import { Inject, Injectable } from '@angular/core';
import { CONFIG_TOKEN } from 'config-token';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Config } from 'src/app/config';
import { Role } from 'src/app/shared/enums/role.enum';
import { RoleService } from './role.service';

@Injectable()
export class RoleMatcherService {
  constructor(@Inject(CONFIG_TOKEN) private readonly config: Config, private readonly roleService: RoleService) {}

  public match$(allowedRoles: Role[]): Observable<boolean> {
    return this.roleService.roles$.pipe(
      map((roles: string[]) => this.config.mockRoles || roles.every((role: string) => allowedRoles.includes(role as Role)))
    );
  }
}
