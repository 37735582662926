import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  NbButtonModule,
  NbCardModule,
  NbDatepickerModule,
  NbIconModule,
  NbInputModule,
  NbLayoutModule,
  NbListModule,
  NbSelectModule,
  NbSpinnerModule,
  NbToastrModule,
} from '@nebular/theme';
import { TranslateModule } from '@ngx-translate/core';

import { AuthModule } from '../auth/auth.module';
import { DetailsLayoutComponent } from './components/details-layout/details-layout.component';
import { EditLayoutComponent } from './components/edit-layout/edit-layout.component';
import { FileInputComponent } from './components/file-input/file-input.component';
import { FileUploadButtonComponent } from './components/file-upload-button/file-upload-button.component';
import { AutoFilterComponent } from './components/filters/auto-filter/auto-filter.component';
import { BooleanFilterComponent } from './components/filters/boolean-filter/boolean-filter.component';
import { DateFilterComponent } from './components/filters/date-filter/date-filter.component';
import { NumberFilterComponent } from './components/filters/number-filter/number-filter.component';
import { SelectFilterComponent } from './components/filters/select-filter/select-filter.component';
import { StringFilterComponent } from './components/filters/string-filter/string-filter.component';
import { ListInputComponent } from './components/list-input/list-input.component';
import { MessageDialogComponent } from './components/message-dialog/message-dialog.component';
import { SimplemdeConfig } from './components/simplemde/config';
import { SimplemdeComponent } from './components/simplemde/simplemde.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ShouldShowErrorDirective } from './directives/should-show-error.directive';
import { AuthGuard } from './guards/auth.guard';
import { CanDeactivateGuard } from './guards/can-deactivate.guard';
import { PatchedJsonEditorModule } from './modules/patched-json-editor/patched-json-editor.module';
import { ApplyPipe } from './pipes/apply.pipe';
import { EvaIconsPipe } from './pipes/eva-icons.pipe';
import { FILTER_TYPE } from './utils/filter-type';

@NgModule({
  imports: [
    NbLayoutModule,
    NbCardModule,
    NbSpinnerModule,
    NbButtonModule,
    NbInputModule,
    NbSelectModule,
    NbDatepickerModule,
    NbListModule,
    NbIconModule,
    CommonModule,
    NbToastrModule,
    PatchedJsonEditorModule,
    FormsModule,
    AuthModule,
    TranslateModule,
  ],
  declarations: [
    EvaIconsPipe,
    ApplyPipe,
    EditLayoutComponent,
    SimplemdeComponent,
    ConfirmDialogComponent,
    FileUploadButtonComponent,
    FileInputComponent,
    MessageDialogComponent,
    NumberFilterComponent,
    BooleanFilterComponent,
    DateFilterComponent,
    StringFilterComponent,
    SelectFilterComponent,
    AutoFilterComponent,
    DetailsLayoutComponent,
    ListInputComponent,
    ShouldShowErrorDirective,
  ],
  exports: [
    EvaIconsPipe,
    ApplyPipe,
    EditLayoutComponent,
    SimplemdeComponent,
    FileUploadButtonComponent,
    FileInputComponent,
    NumberFilterComponent,
    BooleanFilterComponent,
    DateFilterComponent,
    StringFilterComponent,
    PatchedJsonEditorModule,
    AutoFilterComponent,
    TranslateModule,
    DetailsLayoutComponent,
    ListInputComponent,
    ShouldShowErrorDirective,
  ],
  entryComponents: [ConfirmDialogComponent, MessageDialogComponent],
})
export class SharedModule {
  public static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        AuthGuard,
        CanDeactivateGuard,
        {
          provide: SimplemdeConfig,
          useValue: {
            autosave: { enabled: false, uniqueId: 'MyUniqueID' },
          },
        },
        {
          provide: FILTER_TYPE,
          useValue: {
            name: 'string',
            component: StringFilterComponent,
          },
          multi: true,
        },
        {
          provide: FILTER_TYPE,
          useValue: {
            name: 'number',
            component: NumberFilterComponent,
          },
          multi: true,
        },
        {
          provide: FILTER_TYPE,
          useValue: {
            name: 'boolean',
            component: BooleanFilterComponent,
          },
          multi: true,
        },
        {
          provide: FILTER_TYPE,
          useValue: {
            name: 'date',
            component: DateFilterComponent,
          },
          multi: true,
        },
        {
          provide: FILTER_TYPE,
          useValue: {
            name: 'select',
            component: SelectFilterComponent,
          },
          multi: true,
        },
      ],
    };
  }
}
