import { InjectionToken } from '@angular/core';
import { FilterPropertyType } from '../models/filter-property-type.type';
import { SupportedFilterEntityPropertyType } from '../models/supported-filter-entity-property-type.type';

export interface EntityProperty<T = any> {
  title: string;
  type: SupportedFilterEntityPropertyType;
  filterType?: FilterPropertyType;
  isHiddenFromList: boolean;
  isFilterable: boolean;
  sort?: boolean;
  valuePrepareFunction?: (value: any) => string;
  data?: T;
}

export const FILTER_COLUMN_DATA = new InjectionToken<EntityProperty>('ADM_FILTER_COLUMN_DATA');
