import { Component } from '@angular/core';
import { AdminAdminUserDataService as AdminDataService } from '@launchpad/admin';
import { AdminTransactionDataService } from '@launchpad/channeltransaction';
import { AdminDeviceDataService } from '@launchpad/device';
import { AdminUserDataService } from '@launchpad/user';

@Component({
  selector: 'adm-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public title = 'admin';
  constructor(
    private readonly adminDataService: AdminDataService,
    private readonly deviceDataService: AdminDeviceDataService,
    private readonly userDataService: AdminUserDataService,
    private readonly transactionDataService: AdminTransactionDataService
  ) {
    // // TODO This is just a demonstration of the microbackend services.
    // // tslint:disable-next-line: no-console
    // this.adminDataService.getAdminUsers().subscribe(console.log);
    // // tslint:disable-next-line: no-console
    // this.deviceDataService.getDevices().subscribe(console.log);
    // // tslint:disable-next-line: no-console
    // this.userDataService.getUsersByAdmin().subscribe(console.log);
    // // tslint:disable-next-line: no-console
    // this.transactionDataService.listChannelTransactions().subscribe(console.log);
  }
}
