import { ChangeDetectionStrategy, Component, Inject, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { NbSelectComponent } from '@nebular/theme';
import { EntityProperty, FILTER_COLUMN_DATA } from 'src/app/shared/utils/filter-column-data';
import { SelectFilterOption, SelectFilterOptions } from './select-filter-options.interface';

@Component({
  selector: 'adm-select-filter',
  templateUrl: './select-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectFilterComponent implements ControlValueAccessor {
  @ViewChild(NbSelectComponent, { static: true }) public valueAccessor: ControlValueAccessor;
  public placeholder: string;
  public options: SelectFilterOption[] = [];

  constructor(control: NgControl, @Inject(FILTER_COLUMN_DATA) columnData: EntityProperty<SelectFilterOptions>) {
    control.valueAccessor = this;
    this.placeholder = columnData.title;
    this.options = columnData.data?.options || [];
  }

  public writeValue(value: any): void {
    this.valueAccessor.writeValue(value);
  }

  public registerOnChange(fn: any): void {
    this.valueAccessor.registerOnChange(fn);
  }

  public registerOnTouched(fn: any): void {
    this.valueAccessor.registerOnTouched(fn);
  }

  public setDisabledState(isDisabled: boolean): void {
    this.valueAccessor.setDisabledState(isDisabled);
  }
}
