import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector, Type } from '@angular/core';
import { Router } from '@angular/router';
import { NbAuthService } from '@nebular/auth';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

const HTTP_STATUS_UNAUTHORIZED = 401;
const HTTP_STATUS_FORBIDDEN = 403;

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private readonly injector: Injector, private readonly router: Router) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (req.url.includes('/oauth/oauth/token')) {
      return next.handle(req);
    }
    return next.handle(req).pipe(catchError((error: HttpErrorResponse) => this.handleAuthError$(error)));
  }

  private handleAuthError$(error: HttpErrorResponse): Observable<any> {
    const authService: NbAuthService = this.injector.get(NbAuthService as Type<NbAuthService>);
    if (error.status === HTTP_STATUS_UNAUTHORIZED || error.status === HTTP_STATUS_FORBIDDEN) {
      return authService.logout('email').pipe(tap(() => this.router.navigate(['auth/login'])));
    }
    return throwError(error);
  }
}
