import { Inject, Injectable } from '@angular/core';
import {
  NbAuthOAuth2JWTToken,
  NbAuthService,
  NbAuthStrategy,
  NbOAuth2AuthStrategy,
  NbOAuth2ClientAuthMethod,
  NbOAuth2GrantType,
  NbTokenService,
  NB_AUTH_STRATEGIES,
} from '@nebular/auth';
import { CONFIG_TOKEN } from 'config-token';
import { Config } from 'src/app/config';

@Injectable()
export class AuthService extends NbAuthService {
  constructor(
    @Inject(CONFIG_TOKEN) private readonly config: Config,
    @Inject(NB_AUTH_STRATEGIES) protected strategies: NbAuthStrategy[],
    protected tokenService: NbTokenService
  ) {
    super(tokenService, strategies);

    const authStrategy = this.getStrategy('email') as NbOAuth2AuthStrategy;
    authStrategy.setOptions({
      name: 'email',
      baseEndpoint: this.config.backendUrl,
      clientAuthMethod: NbOAuth2ClientAuthMethod.BASIC,
      clientId: this.config.authClientId,
      clientSecret: this.config.authClientSecret,
      token: {
        class: NbAuthOAuth2JWTToken,
        endpoint: '/oauth/oauth/token',
        scope: 'all',
        grantType: NbOAuth2GrantType.PASSWORD,
      },
      authorize: {
        endpoint: '/auth/login',
        scope: 'all',
      },
      refresh: {
        endpoint: '/oauth/oauth/token',
        scope: 'all',
      },
    });
  }
}
