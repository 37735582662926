import { HttpClient, HttpClientModule, HttpRequest } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  NbAuthModule,
  NbAuthOAuth2JWTToken,
  NbOAuth2AuthStrategy,
  NbOAuth2ClientAuthMethod,
  NB_AUTH_TOKEN_INTERCEPTOR_FILTER,
} from '@nebular/auth';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import {
  NbDatepickerModule,
  NbDialogModule,
  NbLayoutModule,
  NbMenuModule,
  NbSidebarModule,
  NbThemeModule,
  NbToastrModule,
} from '@nebular/theme';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CONFIG_TOKEN } from 'config-token';
import { ApiModule } from './api/api.module';
// TODO: import your data service
// import { BASE_PATH } from '@yoreevo/data';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { Config } from './config';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';

// tslint:disable-next-line: only-arrow-functions
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgbModule,
    NbEvaIconsModule,
    NbThemeModule.forRoot({ name: 'default' }),
    NbLayoutModule,
    NbAuthModule.forRoot({
      strategies: [
        NbOAuth2AuthStrategy.setup({
          // reconfigured in ./auth/services/auth.service.ts
          name: 'email',
          baseEndpoint: '',
          clientAuthMethod: NbOAuth2ClientAuthMethod.BASIC,
          clientId: '',
          token: {
            class: NbAuthOAuth2JWTToken,
            endpoint: '/oauth/oauth/token',
            scope: 'all',
            grantType: 'password',
          },
        }),
      ],
      forms: {},
    }),
    AuthModule.forRoot(),
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbToastrModule.forRoot(),
    NbDialogModule.forRoot(),
    NbDatepickerModule.forRoot(),
    SharedModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: 'en',
    }),
    ApiModule,
    CoreModule,
  ],
  providers: [
    {
      provide: '',
      useFactory: (config: Config): string => {
        return config.backendUrl;
      },
      deps: [CONFIG_TOKEN],
    },
    {
      provide: NB_AUTH_TOKEN_INTERCEPTOR_FILTER,
      useValue: (request: HttpRequest<any>): boolean => {
        if (
          (request.responseType === 'json' && request.url.includes('assets/config/config.json')) ||
          request.url.includes('/oauth/oauth/token')
        ) {
          return true;
        }
        return false;
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(translate: TranslateService) {
    translate.setDefaultLang('en');
    translate.use('en');
  }
}
