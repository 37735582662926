import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { PatchedJsonEditorComponent } from './patched-json-editor.component';

@NgModule({
  imports: [CommonModule, NgJsonEditorModule],
  declarations: [PatchedJsonEditorComponent],
  exports: [PatchedJsonEditorComponent],
})
export class PatchedJsonEditorModule {}
