import { Injectable } from '@angular/core';
import { NbAuthToken, NbTokenService } from '@nebular/auth';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Injectable()
export class RoleService {
  public roles$: Observable<string[]> = this.tokenService.tokenChange().pipe(
    map((x: NbAuthToken) => x.getPayload()),
    map((token: any) => token.roles),
    map((roles: string | string[]) => (Array.isArray(roles) ? roles : [roles])),
    shareReplay()
  );

  constructor(private readonly tokenService: NbTokenService) {}
}
