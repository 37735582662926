import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NbAuthJWTInterceptor, NbAuthModule, NbAuthService } from '@nebular/auth';
import { NbAlertModule, NbButtonModule, NbCheckboxModule, NbInputModule } from '@nebular/theme';
import { TranslateModule } from '@ngx-translate/core';
import { AuthRoutingModule } from './auth-routing.module';
import { DisabledByAuthDirective } from './directives/disabled-by-auth.directive';
import { DisabledFormByAuthDirective } from './directives/disabled-form-by-auth.directive';
import { IfByAuthDirective } from './directives/if-by-auth.directive';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { CredentialsInterceptor } from './interceptors/credentials.interceptor';
import { LoginComponent } from './login/login.component';
import { AuthService } from './services/auth.service';
import { RoleMatcherService } from './services/role-matcher.service';
import { RoleService } from './services/role.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NbAlertModule,
    NbInputModule,
    NbButtonModule,
    NbCheckboxModule,
    AuthRoutingModule,
    NbAuthModule,
    TranslateModule,
  ],
  declarations: [LoginComponent, DisabledByAuthDirective, IfByAuthDirective, DisabledFormByAuthDirective],
  exports: [DisabledByAuthDirective, IfByAuthDirective, DisabledFormByAuthDirective],
})
export class AuthModule {
  public static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        {
          provide: NbAuthService,
          useClass: AuthService,
        },
        RoleService,
        RoleMatcherService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: CredentialsInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthInterceptor,
          multi: true,
        },
        { provide: HTTP_INTERCEPTORS, useClass: NbAuthJWTInterceptor, multi: true },
      ],
    };
  }
}
