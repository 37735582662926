import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, Injector } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import { CONFIG_TOKEN } from 'config-token';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Config } from 'src/app/config';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(@Inject(CONFIG_TOKEN) private readonly config: Config, private readonly injector: Injector) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!request.url.startsWith(this.config.backendUrl)) {
      return next.handle(request);
    }
    const toastrService = this.injector.get(NbToastrService);
    return next.handle(request).pipe(
      catchError((errorResponse: HttpErrorResponse) => {
        toastrService.show(status, errorResponse.message, { status: 'danger' });

        return throwError(errorResponse);
      })
    );
  }
}
