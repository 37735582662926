<h1 id="title" class="title">{{ 'Common.Application.name' | translate }}</h1>
<p class="sub-title">{{ 'Auth.Login.welcome' | translate }}</p>

<nb-alert *ngIf="showMessages.error && errors?.length && !submitted" outline="danger" role="alert">
  <p class="alert-title">
    <strong>{{ 'Common.Error.title' | translate }}</strong>
  </p>
  <ul class="alert-message-list">
    <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
  </ul>
</nb-alert>

<nb-alert *ngIf="showMessages.success && messages?.length && !submitted" outline="success" role="alert">
  <p class="alert-title">
    <strong>{{ 'Common.Success.title' | translate }}</strong>
  </p>
  <ul class="alert-message-list">
    <li *ngFor="let message of messages" class="alert-message">{{ message }}</li>
  </ul>
</nb-alert>

<form (ngSubmit)="login()" #form="ngForm" aria-labelledby="title">
  <div class="form-control-group">
    <label class="label" for="input-email">{{ 'Auth.Login.username' | translate }}</label>
    <input
      nbInput
      email
      fullWidth
      autofocus
      id="input-email"
      name="email"
      [(ngModel)]="user.email"
      [placeholder]="'Auth.Login.username' | translate"
      [status]="email.dirty ? (email.invalid ? 'danger' : 'success') : ''"
      [required]="getConfigValue('forms.validation.email.required')"
      [attr.aria-invalid]="email.invalid && email.touched ? true : null"
      #email="ngModel"
    />
    <ng-container *ngIf="email.invalid && email.touched">
      <div class="validation-error" *ngIf="email.errors?.required">{{ 'Auth.Login.emailRequired' | translate }}</div>
      <div class="validation-error" *ngIf="email.errors?.email">{{ 'Auth.Login.emailReal' | translate }}</div>
    </ng-container>
  </div>

  <div class="form-control-group">
    <label class="label" for="input-password">{{ 'Auth.Login.password' | translate }}</label>
    <input
      nbInput
      fullWidth
      type="password"
      id="input-password"
      name="password"
      [(ngModel)]="user.password"
      #password="ngModel"
      [placeholder]="'Auth.Login.password' | translate"
      [status]="password.dirty ? (password.invalid ? 'danger' : 'success') : ''"
      [required]="getConfigValue('forms.validation.password.required')"
      [minlength]="getConfigValue('forms.validation.password.minLength')"
      [maxlength]="getConfigValue('forms.validation.password.maxLength')"
      [attr.aria-invalid]="password.invalid && password.touched ? true : null"
    />
    <ng-container *ngIf="password.invalid && password.touched">
      <div class="validation-error" *ngIf="password.errors?.required">{{ 'Auth.Login.passwordRequired' | translate }}</div>
      <div class="validation-error" *ngIf="password.errors?.minlength || password.errors?.maxlength">
        {{
          'Auth.Login.passwordLength'
            | translate
              : {
                  minLength: getConfigValue('forms.validation.password.minLength'),
                  maxLength: getConfigValue('forms.validation.password.maxLength')
                }
        }}
      </div>
    </ng-container>
  </div>

  <button nbButton fullWidth status="success" [disabled]="submitted || !form.valid" [class.btn-pulse]="submitted">
    {{ 'Auth.Login.login' | translate }}
  </button>
</form>
